import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { ApiResult, OperatorCancellationInfoResult, OperatorCancellationRequest } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getOperatorCancellationPenaltyInfo(reservationId: number): Promise<AxiosResponse<OperatorCancellationInfoResult>> {
    const host = baseUrl()
    const url = `https://${host}/operatorCancellation/${reservationId}/cancellationPenaltyInfo`
    return httpService.get(url)
  },
  operatorRequestCancel(payload: OperatorCancellationRequest): Promise<AxiosResponse<OperatorCancellationInfoResult>> {
    const host = baseUrl()
    const url = `https://${host}/operatorCancellation/requestCancellation`
    return httpService.post(url, payload)
  },
  operatorCancelConfirm(payload: { operatorPaymentProfileId: number }): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/operatorCancellation/cancel`
    return httpService.post(url, payload)
  },
  operatorDenyConfirm(payload: { denyCancellationReason: string }): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/operatorCancellation/deny`
    return httpService.post(url, payload)
  },
  operatorChargePenalty(payload: OperatorCancellationRequest): Promise<AxiosResponse<OperatorCancellationInfoResult>> {
    const host = baseUrl()
    const url = `https://${host}/operatorCancellation/charge`
    return httpService.post(url, payload)
  },
}
